<div *ngIf="shop.active == false && productSearch.orderType != 'Design'" class="lead text-center py-4 mb-4 bg-grey-dark2 white">
    <ng-container *ngIf="shop.statusNotes; else defaultShopCloseMessage">
        <p class="mb-0">
            <span class="icon-info mr-2"></span>
            <span [innerHTML]="shop.statusNotes"></span>
        </p>
    </ng-container>
    <ng-template #defaultShopCloseMessage>
        <p class="mb-0">
            <span class="icon-info mr-2"></span>
            {{ shop.title }} is currently de-activated and products cannot be
            purchased at this time
        </p>
    </ng-template>
</div>
<div class="container-fluid promo-bar text-center py-3 mb-4" *ngIf="shop.shopNotice">
    <div class="row">
        <div class="col-12" [innerHTML]="shop.shopNotice"></div>
    </div>
</div>
<div *ngIf="shop.layoutStyle != 'K' && shop.bannerOrSlideshowInd == 'S' && shop.showLandingPageFlag == false" class="container page-content">
    <div class="kukri-slider">
        <div *ngIf="shop.slideshowImage1">
            <a href="{{ shop.slideshowImage1Link }}">
                <img src="{{ bannerUrl }}/{{ shop.slideshowImage1 }}" alt="" class="img-fluid"/>
            </a>
        </div>
        <div *ngIf="shop.slideshowImage2">
            <a href="{{ shop.slideshowImage2Link }}">
                <img src="{{ bannerUrl }}/{{ shop.slideshowImage2 }}" alt="" class="img-fluid"/>
            </a>
        </div>
        <div *ngIf="shop.slideshowImage3">
            <a href="{{ shop.slideshowImage3Link }}">
                <img src="{{ bannerUrl }}/{{ shop.slideshowImage3 }}" alt="" class="img-fluid"/>
            </a>
        </div>
        <div *ngIf="shop.slideshowImage4">
            <a href="{{ shop.slideshowImage4Link }}">
                <img src="{{ bannerUrl }}/{{ shop.slideshowImage4 }}" alt="" class="img-fluid"/>
            </a>
        </div>
        <div *ngIf="shop.slideshowImage5">
            <a href="{{ shop.slideshowImage5Link }}">
                <img src="{{ bannerUrl }}/{{ shop.slideshowImage5 }}" alt="" class="img-fluid"/>
            </a>
        </div>
        <div *ngIf="shop.slideshowImage6">
            <a href="{{ shop.slideshowImage6Link }}">
                <img src="{{ bannerUrl }}/{{ shop.slideshowImage6 }}" alt="" class="img-fluid"/>
            </a>
        </div>
    </div>
</div>
<div
    class="container-fluid promo-bar text-center py-3 mb-4"
    *ngIf="
        shop.shopTypeString == 'K' &&
        shop.bannerOrSlideshowInd == 'B' &&
        shop.slideshowImage1 &&
        shop.showLandingPageFlag == false
    "
>
    <a href="{{ shop.slideshowImage1Link }}">
        <img src="{{ bannerUrl }}/{{ shop.slideshowImage1 }}" alt="" class="img-fluid"/>
    </a>
</div>
<div
    *ngIf="
        shop.active == true &&
        productSearch.orderType != 'Design' &&
        shop.insideDateWindow == false
    "
    class="shop_outsidedatewindow page-title__wrap"
>
    {{ shop.title }} is currently closed and products cannot be purchased at
    this time
    <p>{{ shop.shopWindowMessage }}</p>
</div>

<div class="container page-content">

    <div class="row product-filtered-grid">
        <!-- TODO: Will likely need to add further checked like is it Kukri UK Shop?... -->
        <div class="col-12 col-lg-3">
            <div class="mobile-filters-tab">
                <a href="javascript:void(0);" class="mobile-filters-tab__filter">Filter</a>
                <a href="javascript:void(0);" class="mobile-filters-tab__sort">Sort</a>
            </div>

            <div class="mobile-filters-content__filter">
                <a *ngIf="shop.layoutStyle == 'V' && shop.showKukriLogoFlag && shop.showShopLogoFlag &&
                        shop.shopLogoFullPath" href="{{ baseShopUrl }}" style="width: 100%; display: block; text-align: center;">
                    <img src="{{ shop.shopLogoFullPath }}" style="max-height:120px;" class="mb-4"/>
                </a>
                <span class="filter__header">
                    Filter Products
                </span>

                <!-- TODO: & Kukri Shop! Will be a beta test before changing all sites to this approach -->
                <div class="filter">
                    <div class="filter__title--static mb-3">
                        Your selection
                    </div>
                    <div class="filter__content active mb-3"
                         *ngIf="productSearch.sideFilterSearchCategories.length > 1">
                        <ul class="filter__selection">
                            <li *ngIf="productSearch.shopProductGroupSearchCategory.selected !== false">
                                <a class="filter__selection--remove" href="#"
                                   (click)="removeFilterCategory(productSearch.shopProductGroupSearchCategory.code)">
                                </a>
                                Product Group
                            </li>
                            <li *ngIf="productSearch.priceFilter">
                                <a class="filter__selection--remove" href="#"
                                   (click)="removeFilterCategory('price')">
                                </a>
                                Price Range
                            </li>
                            <ng-container *ngFor="let filterCat of productSearch.sideFilterSearchCategories">
                                <li *ngIf="filterCat.selected">
                                    <a class="filter__selection--remove" href="#"
                                        (click)="removeFilterCategory(filterCat.code)">
                                    </a>
                                    {{ filterCat.description }}
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>

                <!-- Main Desktop Filter Section -->
                <form action="" class="row mx-0">
                    <div class="filter col-12" *ngIf="shop.showShopProductGroupsFlag">
                        <a href="javascript:void(0);" class="filter__title">
                            {{productSearch.shopProductGroupSearchCategory.description}}
                        </a>
                        <div class="filter__content mt-3">
                            <ul class="filter--list-amount">
                                <li *ngFor="let option of productSearch.shopProductGroupSearchCategory.searchCategoryOptions">
                                    <div class="form-group">
                                        <input
                                            type="checkbox"
                                            name="spg-{{ option.id }}"
                                            id="spg-{{ option.id }}"
                                            [(ngModel)]="option.selected"/>
<!--                                            (change)="filterBackEnd(productSearch.shopProductGroupSearchCategory.code)"-->

                                        <label for="spg-{{ option.id }}">
                                            {{ option.description}}
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <ng-container *ngFor="let filterCat of productSearch.sideFilterSearchCategories">
                        <div class="filter col-12"
                             *ngIf="filterCat.resultsCount > 0">
                            <a href="javascript:void(0);" class="filter__title">
                                {{ filterCat.description }}
                            </a>
                            <div class="filter__content mt-3">
                                <div *ngIf="filterCat.description != 'Size Code' &&
                                    filterCat.description != 'Primary Colour' &&
                                    filterCat.description != 'Secondary Colour' &&
                                    filterCat.description != 'Colour Range'">
                                    <ul class="filter--list-amount">
                                        <li *ngFor="let option of filterCat.searchCategoryOptions">
                                            <div class="form-group"
                                                 *ngIf="productSearch.orderType == 'Off-The-Shelf' ||
                                                 productSearch.orderType == 'Design'">
                                                <input
                                                    type="checkbox"
                                                    name="{{ filterCat.id }}-{{ option.id }}"
                                                    id="{{ filterCat.id }}-{{ option.id }}a"
                                                    [(ngModel)]="option.selected"/>

                                                <label for="{{ filterCat.id }}-{{ option.id }}a">
                                                    {{ option.description }}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div *ngIf="filterCat.description =='Colour Range'">
                                    <ul class="filter--list-amount">
                                        <li *ngFor="let option of filterCat.searchCategoryOptions">
                                            <div class="form-group"
                                                *ngIf="(productSearch.orderType == 'Off-The-Shelf' &&
                                                    option.resultsCount >0) || productSearch.orderType =='Design'">
                                                <input
                                                    type="checkbox"
                                                    name="{{ filterCat.id }}-{{ option.id }}"
                                                    id="{{ filterCat.id }}-{{ option.id }}"
                                                    [(ngModel)]="option.selected"/>

                                                <label for="{{ filterCat.id }}-{{ option.id }}">
                                                    {{ option.description }}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div *ngIf="filterCat.description == 'Size Code'">
                                    <ul class="filter--list-size">
                                        <li *ngFor="let option of filterCat.searchCategoryOptions">
                                            <div class="form-group" *ngIf="option.resultsCount > 0">
                                                <input
                                                    type="checkbox"
                                                    name="size-{{ option.id }}"
                                                    id="size-{{ option.id }}"
                                                    [(ngModel)]="option.selected"/>

                                                <label for="size-{{ option.id }}">
                                                    {{option.description}}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div *ngIf="filterCat.description == 'Primary Colour'">
                                    <ul class="filter--list-colour">
                                        <li *ngFor="let option of filterCat.searchCategoryOptions">
                                            <div class="form-group" *ngIf="option.resultsCount > 0">
                                                <div class="input-colour">
                                                    <input
                                                        id="primary-color-{{ option.id }}"
                                                        name="primary-color-{{ option.id }}"
                                                        type="checkbox"
                                                        [(ngModel)]="option.selected"/>

                                                    <label
                                                        class="input-colour__label"
                                                        for="primary-color-{{ option.id }}"
                                                    >
                                                        <div class="checkbox-colour__wrap">
                                                            <span class="colour--primary" [style.background-color]="option.hexCode"></span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div *ngIf="filterCat.description =='Secondary Colour'">
                                    <ul class="filter--list-colour">
                                        <li *ngFor="let option of filterCat.searchCategoryOptions">
                                            <div class="form-group" *ngIf="option.resultsCount > 0">
                                                <div class="input-colour">
                                                    <input
                                                        id="secondary-color-{{ option.id }}"
                                                        name="secondary-color-{{ option.id }}"
                                                        type="checkbox"
                                                        [(ngModel)]="option.selected"/>

                                                    <label class="input-colour__label" for="secondary-color-{{ option.id }}">
                                                        <div class="checkbox-colour__wrap">
                                                            <span class="colour--secondary" [style.background-color]="option.hexCode"></span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="filter col-3"
                         *ngIf="productSearch.sleeveTypeSearchCategory != null &&
                               productSearch.sleeveTypeSearchCategory.description == 'Sleeve Type'"
                    >
                        <a href="javascript:void(0);" class="filter__title">
                            Sleeve Type
                        </a>

                        <ul class="filter--list-amount">
                            <li *ngFor="let option of productSearch.sleeveTypeSearchCategory.searchCategoryOptions">
                                <div
                                    class="form-group"
                                    *ngIf="productSearch.orderType == 'Design'"
                                >
                                    <input
                                        type="checkbox"
                                        name="sleeveType-{{ productSearch.sleeveTypeSearchCategory.id }}-{{ option.id }}"
                                        id="sleeveType-{{  productSearch.sleeveTypeSearchCategory.id }}-{{ option.id }}"
                                        [(ngModel)]="option.selected"
                                        (change)="filterSleeveType()"
                                    />
                                    <label for="sleeveType-{{ productSearch.sleeveTypeSearchCategory.id }}-{{ option.id }}">
                                        {{ option.description }}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="filter col-12" *ngIf="productSearch.orderType == 'Off-The-Shelf' ||
                        (productSearch.orderType == 'Design' && !kdHidePricesFlag)"
                        [class.d-none]="isShopKukriUK || shop.url == 'ulsterrugby'"
                        [class.d-block]="designUKAdmin">
                        <a href="javascript:void(0);" class="filter__title">
                            Price Range
                        </a>
                        <div class="filter__content mt-3">
                            <div data-role="rangeslider" class="ui-content">
                                <label for="price-min">
                                    Min:{{ productSearch.priceMinLimit}}
                                </label>
                                <input
                                    type="range"
                                    name="price-min"
                                    id="price-min"
                                    value="{{ productSearch.priceMinLimit }}"
                                    [(ngModel)]="productSearch.priceMinLimit"
                                    min="{{ productSearch.priceMin }}"
                                    max="{{ productSearch.priceMax }}"
                                    (change)="filterPrices()"
                                />
                            </div>
                            <div data-role="rangeslider" class="ui-content">
                                <label for="price-max">
                                    Max:{{ productSearch.priceMaxLimit }}
                                </label>
                                <input
                                    type="range"
                                    name="price-max"
                                    id="price-max"
                                    value="{{ productSearch.priceMaxLimit }}"
                                    [(ngModel)]="productSearch.priceMaxLimit"
                                    min="{{ productSearch.priceMin }}"
                                    max="{{ productSearch.priceMax }}"
                                    (change)="filterPrices()"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <button type="submit" id="submitFilter" class="btn btn-primary mt-md-1"
                                (click)="filterBackEnd(undefined)">Ok</button>
                    </div>
                </form>
            </div>

            <div class="mobile-filters-content__sort" [class.d-none]="(isShopKukriUK || shop.url == 'ulsterrugby') && !designUKAdmin">
                <div class="filter d-block"
                     [class.d-lg-none]="(isShopKukriUK == false && shop.url != 'ulsterrugby') || designUKAdmin"
                     [class.filter__sort--uk]="(isShopKukriUK || shop.url == 'ulsterrugby') && !designUKAdmin"
                >
                    <div class="filter__content active">
                        <input
                            class="d-none"
                            type="radio"
                            name="radio-sort"
                            id="sort-by-0"
                            value="position"
                            [(ngModel)]="productSearch.sortBy"
                            (change)="filterBackEnd('productSearch.sortBy')"
                        />
                        <label for="sort-by-0">Recommended</label>

                        <input
                            class="d-none"
                            type="radio"
                            name="radio-sort"
                            id="sort-by-1"
                            value="name"
                            [(ngModel)]="productSearch.sortBy"
                            (change)="filterBackEnd('productSearch.sortBy')"
                        />
                        <label for="sort-by-1">Name (A to Z)</label>

                        <input
                            class="d-none"
                            type="radio"
                            name="radio-sort"
                            id="sort-by-2"
                            value="pricelh"
                            [(ngModel)]="productSearch.sortBy"
                            (change)="filterBackEnd('productSearch.sortBy')"
                        />
                        <label for="sort-by-2">Price (Low to High)</label>

                        <input
                            class="d-none"
                            type="radio"
                            name="radio-sort"
                            id="sort-by-3"
                            value="pricehl"
                            [(ngModel)]="productSearch.sortBy"
                            (change)="filterBackEnd('productSearch.sortBy')"
                        />
                        <label for="sort-by-3">Price (High to Low)</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-9">

            <div class="row is-flex mb-3"
                 [class.d-none]="productSearch.orderType == 'Design' && site.systemCountry.countryCode == 'UK' && !designUKAdmin"
            >
                <div class="col-12 col-lg-6 col-xl-3">
                    <span class="product-filtered-grid__count">
                        {{ resultsRetrievedCount }} of {{ resultsTotalCount }} products
                    </span>
                </div>
                <div class="col-xl-5 d-none d-xl-block" *ngIf="(isShopKukriUK == false && shop.url != 'ulsterrugby') || designUKAdmin">
                    <span class="product-filtered-grid__view">
                        <span class="product-filtered-grid__view__text">
                            View
                        </span>
                        <a
                            href="javascript:void(0);"
                            class="product-filtered-grid__view--three {{ view3active }}"
                            (click)="setActiveView(3)"
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </a>
                        <a
                            href="javascript:void(0);"
                            class="product-filtered-grid__view--four {{ view4active }}"
                            (click)="setActiveView(4)"
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </a>
                    </span>
                </div>

                <div class="col-md-6 col-xl-4 d-none" [class.d-lg-block]="(isShopKukriUK == false && shop.url != 'ulsterrugby') || designUKAdmin">
                    <form class="form-inline d-flex justify-content-end">
                        <div class="form-group">
                            <label for="sort-by" class="mr-3">Sort by</label>
                            <select
                                class="form-control"
                                name="sort-by"
                                id="sort-by"
                                [(ngModel)]="productSearch.sortBy"
                                (change)="filterBackEnd('sortBy')"
                            >
                                <option value="position" selected>Recommended</option>
                                <option value="name">Name (A to Z)</option>
                                <option value="pricelh">Price (Low to High)</option>
                                <option value="pricehl">Price (High to Low)</option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>

            <div class="row mb-2" *ngIf="productSearch.featuredProducts">
                <div class="col-12">
                    <h2 [style.color]="shop.shopTitleBkgroundColour" class="home-page-heading d-inline-block">
                        Featured Products
                    </h2>
                    <a href="/teamshop/{{ shop.url }}/productNavListNg.action" class="float-right d-inline-block btn btn--secondary">
                        Show All Products
                    </a>
                </div>
            </div>

            <div id="filter-products" class="row margin-col is-flex">
               <ng-container *ngFor="let productGroup of productGroups; let productGroupIdx = index">
                    <ng-container *ngFor="let groupProduct of productGroup.products; let groupProductPosition = index">
                        <div *ngIf="groupProductPosition === productGroup.default"
                             id="productGroup-{{productGroupIdx}}" class="{{ gridSwitch }} mb-4">
                            <ng-container>
                                <a href="{{ deriveProductUrl(groupProduct) }}" class="shop-item"
                                   (click)="gtmProductClick(groupProduct,'Main Products Listing Page', groupProductPosition)">
                                    <div class="shop-item__image-wrap">
                                        <!-- OFF THE SHELF PRODUCTS -->
                                        <img *ngIf="groupProduct.productType == 'Stock' || groupProduct.productType == 'CustomisedStock' || groupProduct.productType == 'Bundle'"
                                             class="shop-item__image position-absolute" alt="" offset="0" errorImage="../images/placeholder.png"
                                             [lazyLoad]="groupProduct.imageFront" width="545" height="545"
                                        />

                                        <!-- DISPLAY LOGO ON TOP OF PRODUCT -->
                                        <ng-container *ngIf="groupProduct.productAttributes != null && groupProduct.productType == 'CustomisedStock' &&
                                            groupProduct.dynamicNanImageFlag == true && imageOverride(groupProduct.imageFront) == false">
                                            <!-- HTML5 CANVAS FOR PRODUCT LOGOS -->
                                            <!-- TODO: Use this logic for the HTML5 Canvas -->
                                            <canvas draw-logos id="productImageLogoCanvas-{{ productGroupIdx }}" class="canvas__product--dynamic-logo"
                                                    [productAttributes]="groupProduct.productAttributes">
                                            </canvas>
                                        </ng-container>

                                        <!-- DESIGN PRODUCTS -->
                                        <div *ngIf="(groupProduct.productType == 'DesignTemplate' ||
                                            groupProduct.productType == 'Design') && groupProduct.imageFront">
                                            <span class="shop-item__svg" [innerHtml]="groupProduct.imageFront | resizesvg: 100:100:'%'"></span>
                                        </div>

                                        <!-- IMAGE LAZYLOAD PLACEHOLDER -->
                                        <img class="shop-item__placeholder" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 545 545'%3E %3Crect fill%3D'%23f0f0f0' width%3D'100%25' height%3D'100%25'%2F%3E%3C%2Fsvg%3E" width="545" height="545" alt=""/>

                                        <!-- SALE / NEW BADGES -->
                                        <div *ngIf="groupProduct.newTag" class="ribbon-wrapper">
                                            <div class="ribbon">{{ groupProduct.newTag }}</div>
                                        </div>

                                        <div *ngIf="groupProduct.saleTag && shop.showWasnowPricingFlag" class="sale-ribbon sale-ribbon-round">
                                            {{ groupProduct.saleTag }}
                                        </div>
                                    </div>

                                    <div class="shop-item__details">
                                        <div class="shop-item__details__title">
                                            {{
                                                productGroup.products.length > 1 ?
                                                sanitiseProductName(productGroup.products[0]) : productGroup.products[0].description
                                            }}
                                        </div>

                                        <div *ngIf="groupProduct.supportedGenderCategories && groupProduct.supportedGenderCategories.length > 0">
                                            <small>
                                                Available in: {{groupProduct.supportedGenderCategories}}
                                            </small>
                                        </div>
                                        <div *ngIf="groupProduct.code">
                                            <small>
                                                Code: {{groupProduct.code}}
                                            </small>
                                        </div>

                                        <div class="shop-item__details__price">
                                            <ng-container *ngIf="shop.showWasnowPricingFlag != null && shop.showWasnowPricingFlag == true">
                                                <del *ngIf="groupProduct.wasUnitChargeExclTax != null && groupProduct.wasUnitChargeExclTax != undefined">
                                                    <span class="price-display">
                                                        <span class="PriceCurrencySymbol">
                                                            {{ productSearch.currencySymbol }}
                                                        </span>
                                                        {{ groupProduct.wasUnitChargeExclTax | number: "1.2-2" }}
                                                    </span>
                                                </del>
                                            </ng-container>
                                            <ng-container *ngIf="groupProduct.productType != 'DesignTemplate' ||
                                                (groupProduct.productType == 'DesignTemplate' && (!kdHidePricesFlag ||
                                                isKukriAdmin || isShopManager))">
                                                <ins>
                                                    <span class="price-display">
                                                       <small *ngIf="groupProduct.productType == 'DesignTemplate'">
                                                            from
                                                       </small>
                                                        <span class="PriceCurrencySymbol">
                                                            {{ productSearch.currencySymbol }}
                                                        </span>
                                                        {{ groupProduct.unitChargeInclTax | number: "1.2-2" }}
                                                    </span>
                                                </ins>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="shop-item__info-wrap">
                                        <div class="shop-item__info" *ngIf="groupProduct.personalisableTag != null">
                                            <ng-container *ngIf="shop.dynamicShopLogosOnlyFlag == true; else kukriShopTag">
                                                {{ groupProduct.personalisableTag }} - choose your logo
                                            </ng-container>
                                            <ng-template #kukriShopTag>
                                                {{ groupProduct.personalisableTag }}
                                            </ng-template>
                                        </div>
                                    </div>
                                    <small *ngIf="productGroup.products.length > 1">More designs:</small>
                                    <div *ngIf="productGroup.products.length > 1" class="shop-item__choices">
                                        <ng-container *ngFor="let productThumbnail of productGroup.products; let productThumbnailPosition = index">
                                                <div *ngIf="productThumbnail.show && productThumbnailPosition !== 0" class="m-1" id="productThumbnail-{{productGroupIdx}}-{{productThumbnailPosition}}"
                                                     >
                                                    <a href="{{ deriveProductUrl(productThumbnail) }}"
                                                       (click)="gtmProductClick(productThumbnail,'Main Products Listing Page', productThumbnailPosition)">
                                                        <div class="image-wrap-choices">
                                                            <!-- OFF THE SHELF PRODUCTS -->
                                                            <img *ngIf="productThumbnail.productType == 'Stock' ||
                                                                productThumbnail.productType == 'CustomisedStock' ||
                                                                productThumbnail.productType == 'Bundle'"
                                                                 class="choice-img" alt="" offset="0"
                                                                 errorImage="../images/placeholder.png"
                                                                 [lazyLoad]="productThumbnail.imageFront"
                                                            />

                                                            <!-- DESIGN PRODUCTS -->
                                                            <div *ngIf="(productThumbnail.productType == 'DesignTemplate' ||
                                                                productThumbnail.productType == 'Design') && productThumbnail.imageFront">
                                                                <span class="choice-img"
                                                                      [innerHtml]="productThumbnail.imageFront | resizesvg: 100:100:'%'">
                                                                </span>
                                                            </div>

                                                            <!-- SALE / NEW BADGES -->
                                                            <div *ngIf="productThumbnail.newTag" class="ribbon-wrapper">
                                                                <div class="ribbon">{{ productThumbnail.newTag }}</div>
                                                            </div>

                                                            <div *ngIf="productThumbnail.saleTag && shop.showWasnowPricingFlag"
                                                                 class="sale-ribbon sale-ribbon-round">
                                                                {{ productThumbnail.saleTag }}
                                                            </div>
                                                            <small class="d-block">
                                                                Code: {{productThumbnail.code}}
                                                            </small>
                                                        </div>
                                                    </a>
                                                </div>
                                        </ng-container>
                                    </div>
                                    <div id="groupedProducts-{{productGroupIdx}}" *ngIf="productGroup.products.length > 1"
                                         (click)="$event.preventDefault();"
                                         class="shop-item__grouped_products_wrap">
                                        <div class="group_label">
                                            <b>
                                                {{ sanitiseProductName(productGroup.products[0]) }}
                                            </b>
                                            <span>
                                                ({{productGroup.products.length}})
                                            </span>
                                        </div>
                                        <div class="shop-item__grouped_products" (click)="$event.stopPropagation();">
                                            <style>
                                                .tooltip {
                                                    width: 120px;
                                                    bottom: 100%;
                                                    left: 50%;
                                                    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
                                                }
                                            </style>
                                        </div>
                                    </div>
                                </a>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <!-- TODO: Make product count a component -->

            <div class="row" *ngIf="resultsTotalCount > 10 && resultsRetrievedCount != resultsTotalCount">
                <div class="col-12">
                    <span class="product-filtered-grid__count mb-4">
                        {{ resultsRetrievedCount }} of {{ resultsTotalCount }} products
                    </span>
                </div>
                <div class="col-12 text-center" *ngIf="resultsRetrievedCount != resultsTotalCount">
                    <button type="submit" class="btn btn--secondary" (click)="showMore()">
                        Show more
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
